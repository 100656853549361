<template>
    <div>
        <breadcrumbs
            :items="breadItem"
        ></breadcrumbs>
        <div class="header-page">
            <div class="header-font">
                {{headerText}}
            </div>
            <div>
                <v-btn class="mr-2" color="primary create-btn" outlined @click="goToUpdateStudentStatus">Update Status</v-btn>
                <v-btn color="primary" @click="openAddStudentForm()">Add Student</v-btn>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="studentBookingList"
            class="elevation-1"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <div class="text-truncate">
                    <v-icon
                        small
                        @click="deleteModal(item)"
                    >
                        mdi-delete
                    </v-icon>
                </div>
            </template>
        </v-data-table>
        <add-student-to-class-modal
            ref="addStudentForm"
            :studentListForAdd="studentListForAdd"
            :classId="classId"
            @added-success="getDataForThisPage"
        ></add-student-to-class-modal>
        <delete-modal
            ref="deleteModal"
            @delete-success="deleteSuccess"
            :deleteForm="deleteForm"
        ></delete-modal>
    </div>
</template>

<script>
import AddStudentToClassModal from '../components/AddStudentToClassModal.vue'
import DeleteModal from '../components/DeleteModal'
import Breadcrumbs from '../components/Breadcrumbs'
export default {
    name: 'StudentBookingList',
    components: {
        AddStudentToClassModal,
        DeleteModal,
        Breadcrumbs
    },
    data () {
        return {
            studentBookingList: [],
            headers: [
                { text: 'Student Name', value: 'studentName' },
                { text: 'Register Type', value: 'regisType' },
                { text: 'Register Time', value: 'regisDateTime' },
                { text: 'Status', value: 'status' },
                { text: '', value: 'actions' }
            ],
            classId: null,
            headerText: '',
            studentListForAdd: [],
            deleteForm: {
                header: 'Delete student from class',
                title: 'You want to delete branch ',
                url: ''
            },
            breadItem: [
                {
                    text: 'Classes',
                    disabled: false,
                    link: true,
                    exact: true,
                    to: {
                        path: '/ep-classes'
                    }
                },
                {
                    text: '',
                    disabled: true
                }
            ],
            studentBookingObjectIds: {}
        }
    },

    methods: {
        formatDate (date) {
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        async getClassInfo () {
            const res = await this.axios.get(`ep-classes/${this.classId}`)
            if (res && res.data && res.data.status === 'ok') {
                const data = res.data.data
                this.headerText = `${data.name} [${this.formatDate(data.date)}] ${data.startTime} - ${data.endTime}`
                this.breadItem[1].text = data.name
            }
        },

        async getStudentBookingList () {
            const res = await this.axios.get(`ep-classes/${this.classId}/student-booking-list`)
            if (res && res.data && res.data.status === 'ok') {
                this.studentBookingList = res.data.data
                this.createStudentAddedObject()
            }
        },

        async getStudentsHavePermissionForThisClass () {
            const res = await this.axios.get(`ep-classes/${this.classId}/students`)
            if (res && res.data && res.data.status === 'ok') {
                this.studentListForAdd = res.data.data
                this.removeAddedStudentFromStudentListForAdd()
            }
        },

        deleteModal (item) {
            this.deleteForm.title = `You want to delete student from this class ${item.studentName} ?`
            this.deleteForm.url = `/ep-classes/${this.classId}/student-booking-list/${item.id}`
            this.$refs.deleteModal.dialog = true
        },

        goToUpdateStudentStatus (item) {
            this.$router.push({ path: `/ep-classes/${this.classId}/student-booking/status` })
        },

        async deleteSuccess () {
            this.$notify({
                title: 'Congratulations',
                text: 'Delete student in class success',
                type: 'success'
            })
            await this.getDataForThisPage()
        },

        openAddStudentForm () {
            this.$refs.addStudentForm.dialog = true
        },

        createStudentAddedObject () {
            for (let i = 0; i < this.studentBookingList.length; i++) {
                this.studentBookingObjectIds[this.studentBookingList[i].studentCourseId] = true
            }
        },

        removeAddedStudentFromStudentListForAdd () {
            for (let i = 0; i < this.studentListForAdd.length; i++) {
                if (this.studentBookingObjectIds[this.studentListForAdd[i].value]) {
                    this.studentListForAdd.splice(i, 1)
                }
            }
        },

        async getDataForThisPage () {
            this.studentBookingObjectIds = {}
            await this.getStudentBookingList()
            await this.getStudentsHavePermissionForThisClass()
        }
    },

    async mounted () {
        this.classId = this.$route.params.id
        await this.getClassInfo()
        await this.getDataForThisPage()
    }
}
</script>

<style lang="scss" scoped>

</style>
