<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card class="card-padding">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">Add Student</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <v-autocomplete
                        v-model="studentSelectList"
                        :items="studentListForAdd"
                        label="Student"
                        item-text="key"
                        item-value="value"
                        multiple
                    ></v-autocomplete>
                    <div class="btn-container">
                        <v-btn class="mr-4" @click="submit" color="primary">
                            Submit
                        </v-btn>
                        <v-btn @click="closeModal" color="primary" outlined>
                            Cancel
                        </v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'AddStudentToClassModal',
    props: {
        studentListForAdd: {
            type: Array,
            default: function () {
                return []
            }
        },
        classId: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            dialog: false,
            studentSelectList: [],
            search: null
        }
    },

    methods: {
        closeModal () {
            this.dialog = false
            this.search = null
        },

        async submit () {
            const res = await this.axios.post(`ep-classes/${this.classId}/student-booking-list`, {
                studentSelectList: this.studentSelectList
            })
            if (res && res.data && res.data.status === 'ok') {
                this.$emit('added-success')
                this.$notify({
                    title: 'Congratulations',
                    text: 'Add student in class success',
                    type: 'success'
                })
                this.studentSelectList = []
                this.search = null
                this.dialog = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
